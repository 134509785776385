<template>
    <v-main>
        <v-card class="mx-auto my-12" max-width="374" color="#fdf9f9" >
          <v-row > 
            <v-col class="text-center">
              <v-img class="ma-auto" src="../assets/logo.png" width="250" ></v-img>
            </v-col>
          </v-row>
            <v-card-title style="color: #004C8F; justify-content:center; font-weight: bolder; ">
                
            </v-card-title>
            <v-card-text>
                <p v-if="incorrectAuth">Usuário ou senha incorretos.</p>
                <v-form v-on:submit.prevent="login">
                    <v-container>
                        <v-row dense>
                            <v-col>
                                <p style="color: #004C8F;">Entre com seu Usuário e Senha</p>
                                <v-text-field 
                                    outlined
                                    v-model="username"
                                    placeholder="Usuario"
                                    @keyup.enter="login"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col>
                                <v-text-field 
                                  outlined
                                  v-model="password"
                                  placeholder="Senha"
                                  @keyup.enter="login"
                                  :append-icon="tipo ? 'mdi-eye' : 'mdi-eye-off'"
                                  :type="tipo?'text':'password'"
                                  @click:append="tipo = !tipo"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions >
                <v-container style="color: #004C8F;">
                    <v-btn 
                      id="login-1"
                      :loading="loading" 
                      color= #004C8F
                      rounded
                      block
                      elevation="4" 
                      @click.stop="entrar" 
                      outlined
                    >
                      ENTRAR
                      <v-icon
                        class="pa-2"
                        
                      >mdi mdi-login</v-icon>
                    </v-btn>
                </v-container>
            </v-card-actions>
        </v-card>
        <v-snackbar
          v-model="loading"
          height="300"
          width="500"
          :color="cor"
        >
          {{ mensagem }}

          <template v-slot:action="{ attrs }">
            <v-btn
              v-bind="attrs"
              @click="fechaLoading"
              color="white"
              text
            >
              Usuário ou Senha Incorreto, Tente novamente!
            </v-btn>
          </template>
        </v-snackbar>
    </v-main>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { router } from '../routes'
export default {
    name:'Login',
    data:()=>({
        info:[],
        username:"",
        password:"",
        incorrectAuth:false,
        tipo:false
    }), 
    computed:{  
        ...mapState(['count','cor','mensagem']),
        ...mapGetters(['loading'])
    },
    methods:{
        verSenha(){
          this.tipo = 'text'
        },
        login(e){
            if (e.keyCode === 13) {
                this.entrar()
            }
            
        },
        fechaLoading(){
          this.$store.dispatch('btnLoading',false)
          this.$store.dispatch('cor','')
          this.$store.dispatch('mensagem','')

          
        },
        entrar(){
          this.$store.dispatch('btnLoading',true)
          this.$store.dispatch('mensagem',"Solicitando autenticação...")
          this.$store.dispatch('cor',"blue")
          
          //realiza o login 
          this.$store.dispatch('userLogin',{
              method: 'post',
              url: 'api/token/',
              data : {"username":this.username,"password":this.password}
          })  
        }
    },
    mounted(){
      this.$store.dispatch('btnLoading',false)
      if(localStorage.getItem('tokenAccess'))
        router.push('/')
    }
}
</script>